<template>
  <div class="animated fadeIn">
    <b-card header-tag="header" footer-tag="footer">
      <div slot="header">
        <strong>Filtros</strong>
      </div>
      <div class="p-1 mt-2">
        <b-row class="mb-1">
          <b-col sm="12" md="6" lg="4" class="d-md-block">
            <b-button-toolbar class="float-left">
              <b-form-radio-group
                class="mr-3"
                buttons
                button-variant="outline-primary"
                v-model="filtro.fields.periodo"
                @change="changePeriodo"
                name="radiosBtn"
              >
                <b-form-radio class="mx-0" value="Hoje">Hoje</b-form-radio>
                <b-form-radio class="mx-0" value="Ontem">Ontem</b-form-radio>
                <b-form-radio class="mx-0" value="Semana">Semana</b-form-radio>
                <b-form-radio class="mx-0" value="Mes">Mês</b-form-radio>
                <b-form-radio class="mx-0" value="Periodo">Período</b-form-radio>
              </b-form-radio-group>
            </b-button-toolbar>
          </b-col>
          <b-col sm="12" md="6">
            <div class="custom-control custom-checkbox pt-1">
              <input type="checkbox" class="custom-control-input" id="cbRespostaUnica" v-model="filtro.fields.respostaUnica">
              <label class="custom-control-label" for="cbRespostaUnica">Considerar respostas de seleção múltipla como resposta única no "Motivos Detalhe"</label>
            </div>
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col sm="4" class="pr-md-0 mb-1 mb-md-0">
            <b-form-select
              :plain="true"
              v-model="filtro.fields.gestao"
              :options="filtro.listas.gestao"
              @change="filterLojas"
            ></b-form-select>
          </b-col>
          <b-col sm="4" class="pr-md-0 mb-1 mb-md-0">
            <b-form-select
              :plain="true"
              v-model="filtro.fields.centres"
              :options="filtro.listas.centresFiltradosPorGestao"
            ></b-form-select>
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col cols="6" sm="6" md="4" class="pr-md-0 pr-1">
            <b-form-input
              :plain="true"
              v-model="filtro.fields.dataIni"
              type="date"
              @change="dataChanged"
            ></b-form-input>
          </b-col>
          <b-col cols="6" sm="6" md="4" class="pr-md-0 pl-1 pl-md-3">
            <b-form-input
              :plain="true"
              v-model="filtro.fields.dataFim"
              type="date"
              @change="dataChanged"
            ></b-form-input>
          </b-col>
          <b-col sm="1">
            <b-button variant="primary" class="my-2 my-sm-0 text-white" :class="{'btn-block': $mq === 'sm'}" @click="search()">
              <i class="fa fa-search"></i>
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <b-row>
      <b-col cols="12" md="12" lg="4">
        <b-card :no-body="true">
          <b-card-body class="clearfix text-center text-white p-3" body-bg-variant="primary">
            <div class="h2 mb-1 font-weight-bold">{{ qtd_realizada | number }}</div>
            <div class="h5">Vendas Realizadas</div>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col cols="12" md="12" lg="4">
        <b-card :no-body="true">
          <b-card-body class="clearfix text-center text-white p-3" body-bg-variant="primary">
            <div class="h2 mb-1 font-weight-bold">{{ qtd_perdida | number }}</div>
            <div class="h5">Vendas Perdidas</div>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col cols="12" md="12" lg="4">
        <b-card :no-body="true">
          <b-card-body class="clearfix text-center text-white p-3" body-bg-variant="primary">
            <div class="h2 mb-1 font-weight-bold">{{ taxa_conversao | number }}%</div>
            <div class="h5">Taxa de Conversão</div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

    <b-card class="mr-md-12">
      <div slot="header" class="text-black">
        <div class="d-flex">
          <div class="d-flex-inline mr-auto">
            <strong>Motivos em Percentual</strong>
          </div>
          <div class="d-flex-inline ml-auto" v-if="displayChart">
            <i class="fa fa-download text-primary cursor-pointer" @click="export2image('chart-pie-0676', `Motivos em Percentual`)" title="Exportar"></i>
          </div>
        </div>
      </div>
      <pie :chartdata="charts.Motivos" :height="400" chartId="chart-pie-0676" v-if="loaded && displayChart" :percentFormat="true" />
      <div class="alert alert-secondary text-center mb-0" v-if="!displayChart">
        Nenhum resultado encontrado.
      </div>
    </b-card>

    <b-row>
      <b-col cols="12" sm="12" lg="12">
        <b-card header-tag="header">
          <div slot="header" class="float-left">
            <strong>Motivos por {{ tableTitle }}</strong>
          </div>
          <div slot="header" class="float-right" v-if="exportable">
            <download-excel
              :name="`${item.name || `Motivos por ${tableTitle}`}.xls`"
              :fields="exportFields"
              :data="items"
            >
              <i class="fa fa-download cursor-pointer" alt="Exportar" title="Exportar"></i>
            </download-excel>
          </div>
          <b-table
            :items="items"
            :fields="fields"
            hover
            small
            responsive="md"
            class="rotate-header"
          >
          </b-table>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" sm="12" lg="6">
        <b-card>
          <div slot="header" class="text-black">
            <div class="d-flex">
              <div class="d-flex-inline mr-auto">
                <strong>Motivos Detalhe - Gráfico</strong>
              </div>
              <!-- <div class="d-flex-inline ml-auto">
                <i class="fa fa-download text-primary cursor-pointer" @click="export2image('chart-pie-23', `Motivos Detalhe`)" title="Exportar"></i>
              </div> -->
            </div>
          </div>
          <ul id="chart-pie-23" class="horizontal-bars pl-0" v-for="(item, index) in motivosDetTemp" :key="index">
            <div class="progress-group">
              <div class="progress-group-header">
                <span class="title">{{item.desc}}</span>
                <span class="ml-auto font-weight-bold">{{item.qtd}} <span class="text-muted small">({{item.perc | currency({symbol: ''})}} %)</span></span>
              </div>
              <div class="progress-group-bars">
                <b-progress height={} class="progress-sm" :value="item.perc" variant="primary"></b-progress>
              </div>
            </div>
          </ul>
        </b-card>
      </b-col>
      <b-col cols="12" sm="12" lg="6">
        <b-card header-tag="header">
          <div slot="header" class="float-left text-black">
            <strong>Motivos Detalhe - Tabela</strong>
          </div>
          <div slot="header" class="float-right">
            <download-excel
              name="Motivos Detalhe.xls"
              class="float-left"
              :fields="exportMotivosDet"
              :data="motivosDetTemp"
            >
              <i class="fa fa-download cursor-pointer" alt="Exportar" title="Exportar"></i>
            </download-excel>
          </div>
          <b-table
            :items="motivosDetTemp"
            :fields="fieldsMot"
            hover
            small
            responsive="md"
            foot-clone
            @row-clicked="loadNextMotivos"
          >
          <template slot="proximo" slot-scope="data" v-if="data.item.selecao && !respostaUnicaTemp">
            <i class="fa fa-arrow-right" @click="loadNextMotivos"></i>
          </template>
          <template slot="FOOT_desc">
            Total
          </template>
          <template slot="FOOT_qtd">
            {{ (motivosDetTemp[0] ? motivosDetTemp[0].Total : 0) }}
          </template>
          <template slot="FOOT_perc">
            {{ totalPercent | currency({symbol: ''}) }}
          </template>
          </b-table>
          <b-row>
            <b-col cols="12" sm="12" class="text-right">
              <b-button size="sm" variant="outline-primary" @click="loadBackMotivos" v-if="posMotivo.length > 1">Voltar</b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import moment from "moment";
import API from "@/core/api";
import cTable from "@/core/components/Table.vue";
import Pie from "@/core/components/charts/Pie";
import session from "@/shared/session";
import { cleanup } from "@/shared/string";
import lojasPorGestaoUniMixin from '@/core/mixins/lojasPorGestaoUni.mixin.js';

export default {
  name: "VendasPerdidas",
  components: {
    cTable,
    Pie
  },
  mixins:[
    lojasPorGestaoUniMixin
  ],
  data: () => {
    return {
      filtro: {
        listas: {
          gestao: session.get('gestoes'),
          centres: session.get("lojas"),
          centresDefault: session.get('lojas'),
          centresFiltradosPorGestao: []
        },
        fields: {
          periodo: "Hoje",
          gestao: 0,
          centres: 0,
          dataIni: moment().format("YYYY-MM-DD"),
          dataFim: moment().format("YYYY-MM-DD"),
          respostaUnica: false,
          limit: null,
          offset: 0
        }
      },
      respostaUnicaTemp: false,
      fieldHeader: [],
      items: [],
      motivosDet: [],
      motivosDetTemp: [],
      totalPercent: 0,
      posMotivo: [],
      item: {},
      fields: [
        {
          key: "nome_motivo",
          label: "Loja",
          sortable: false
        }
      ],
      fieldsMot: [
        {
          key: "desc",
          label: "Motivo",
          sortable: false
        },
        {
          key: "qtd",
          label: "Quantidade",
          sortable: false,
          thClass: 'text-right',
          tdClass: 'text-right'
        },
        {
          key: "perc",
          label: "Percentual %",
          sortable: false,
          formatter: value => {
            let val = (value/1).toFixed(2).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
          },
          thClass: 'text-right',
          tdClass: 'text-right'
        },
        {
          key: 'proximo',
          label: '',
          tdClass: 'text-right'
        }
      ],
      tableTitle: 'Loja',
      clienteInfo: {},
      charts: { Motivos: {} },
      loaded: false,
      exportable: true,
      qtd_realizada: 0,
      qtd_perdida: 0,
      taxa_conversao: 0,
      currentPage: 1,
      perPage: 50
    };
  },
  methods: {
    async search() {
      try {
        if (this.validaPeriodo() === true) {
          this.loaded = false;

          const vendasRealizadasAPI = new API("api/total-vendas-realizadas");
          const vendaTotal = await vendasRealizadasAPI.get({
            ...this.parseFilters(this.filtro.fields)
          });
          this.qtd_realizada = vendaTotal.data.data.qtd_realizada;
          this.qtd_perdida = vendaTotal.data.data.qtd_perdida;
          this.taxa_conversao = vendaTotal.data.data.taxa_conversao;

          const perdidasAPI = new API("api/vendas-perdidas");
          const data2 = await perdidasAPI.get({
            ...this.parseFilters(this.filtro.fields)
          });
          this.items = data2.data.data;

          const motivosPerdidosAPI = new API("api/vendas-perdidas_motivos");
          const data3 = await motivosPerdidosAPI.get({
            ...this.parseFilters(this.filtro.fields)
          });
          this.motivosDet = data3.data.data;

          this.respostaUnicaTemp = false;

          this.parseMotivos();
          this.posMotivo = [];
          this.loadNextMotivos({}, 0);
    
          this.respostaUnicaTemp = this.filtro.fields.respostaUnica;

          const perdidasPercAPI = new API("api/vendas-perdidas_perc");
          const { data } = await perdidasPercAPI.get({
            ...this.parseFilters(this.filtro.fields)
          });
          this.fieldHeader = data.data;
          this.parseHeader();
          this.parseTable();
          this.loadCharts();

          if (this.filtro.fields.centres !== 0) {
            this.tableTitle = 'Vendedor'
            this.fields[0].label = 'Vendedor'
          } else {
            this.tableTitle = 'Loja'
            this.fields[0].label = 'Loja'
          }
        }
      } catch (e) {
        console.error(e);
      }
    },
    validaPeriodo() {
      const { dataIni, dataFim } = this.filtro.fields;
      let invalid = false;

      if (
        dataIni === '' ||
        dataFim === '' ||
        moment(dataIni).isAfter(moment(dataFim)) ||
        !moment(dataIni).isValid() ||
        !moment(dataFim).isValid()
      ) {
        invalid = true;
      }

      if (invalid) {
        return swal({
          text: 'Data inválida.'
        });
      }
      return true
    },
    parseHeader() {
      this.fields.splice(1);
      const header = this.fieldHeader.map(h =>
        h.records.map(r => {
          this.fields.push({
            key: cleanup(r.nome_motivo),
            label: r.nome_motivo,
            thClass: 'text-right',
            tdClass: 'text-right'
          });
          return r;
        })
      );
    },
    parseTable() {
      this.items = this.items.map(d =>
        d.records.map(r => {
          return { ...r, motivos: JSON.parse(r.motivos) };
        })
      )[0];

      const _items = [];
      const items = this.items.map(i => {
        let item = {
          nome_motivo: i.nome_loja
        };
        i.motivos.map(m => {
          item[cleanup(m.nome_motivo)] = m.qtde_motivo;
          return m;
        });
        _items.push(item);
      });
      this.items = [];
      this.items = _items;
    },
    parseMotivos() {
      this.motivosDet = this.motivosDet.map(d => JSON.parse(d.records));
    },
    loadNextMotivos(element, value) {
      if (this.respostaUnicaTemp) {
        return;
      }

      if (element.fmt === 'RP') {
        return;
      }
      this.posMotivo.push(value);
      let obj = [];
      for (let index = 0; index < this.posMotivo.length; index++) {
        const element = this.posMotivo[index];
        obj = (obj.length > 0 ? obj : this.motivosDet)[element].selecao;
      }
      const sumValue = obj
        .map(r => r.qtd)
        .reduce((accum, curr) => accum + curr);
      obj = obj.map(r => ({
        ...r, perc: (r.qtd * 100) / (sumValue || 1),
        Total: sumValue,
        proximo: r.selecao && r.selecao.length > 0
      }));
      this.motivosDetTemp = obj;

      if (this.motivosDetTemp.length > 0) {
        this.totalPercent = this.motivosDetTemp.map(m => m.perc).reduce((acc, curr) => acc + curr)
      }
    },
    loadBackMotivos() {
      if (this.posMotivo.length === 0) {
        return;
      }
      this.posMotivo.splice(-1, 1);
      let obj = [];
      for (let index = 0; index < this.posMotivo.length; index++) {
        const element = this.posMotivo[index];
        obj = (obj.length > 0 ? obj : this.motivosDet)[element].selecao;
      }
      const sumValue = obj
        .map(r => r.qtd)
        .reduce((accum, curr) => accum + curr);
      obj = obj.map(r => ({
        ...r, perc: (r.qtd * 100) / sumValue,
        Total: sumValue,
        proximo: r.selecao && r.selecao.length > 0
      }));
      this.motivosDetTemp = obj;
      if (this.motivosDetTemp.length > 0) {
        this.totalPercent = this.motivosDetTemp.map(m => m.perc).reduce((acc, curr) => acc + curr)
      }
    },
    loadCharts() {
      this.exibicao = true;
      const mapLabel = this.fieldHeader.map(d => (d.records || []).map(r => r.nome_motivo.replace(';', '.'))).flat().join(';').split(';');
      const mapData = this.fieldHeader.map(d => (d.records || []).map(r => r.perc_pesq)).join().split(',');

      this.charts.Motivos = {
        labels: mapLabel,
        datasets: [
          {
            backgroundColor: this.dynamicColors(mapLabel.length),
            data: mapData
          }
        ]
      };

      this.loaded = true;
    },
    dynamicColors(count) {
      let result = [ ...this.$store.state.colors ];
      for (let i = 0; i < count; i++) {
        const r = Math.floor(Math.random() * 255);
        const g = Math.floor(Math.random() * 255);
        const b = Math.floor(Math.random() * 255);

        result.push("rgb(" + r + "," + g + "," + b + ",.7)")
      }
      return result;
    },
    dataChanged() {
      this.filtro.fields.periodo = "Periodo";
    },
    changePeriodo(periodo) {
      switch (periodo) {
        case "Hoje": {
          this.filtro.fields.dataIni = moment().format("YYYY-MM-DD");
          this.filtro.fields.dataFim = moment().format("YYYY-MM-DD");
          break;
        }
        case "Ontem": {
          this.filtro.fields.dataIni = moment().subtract(1, "day").format("YYYY-MM-DD");
          this.filtro.fields.dataFim = moment().subtract(1, "day").format("YYYY-MM-DD");
          break;
        }
        case "Semana": {
          this.filtro.fields.dataIni = moment().startOf("week").format("YYYY-MM-DD");
          this.filtro.fields.dataFim = moment().endOf("week").format("YYYY-MM-DD");
          break;
        }
        case "Mes": {
          this.filtro.fields.dataIni = moment().startOf("month").format("YYYY-MM-DD");
          this.filtro.fields.dataFim = moment().endOf("month").format("YYYY-MM-DD");
          break;
        }
        default:
          return;
      }
      this.search();
    },
    export2image: function (id, name) {
      // Set White Background
      let chart = document.getElementById(id)
      var ctx = chart.getContext("2d");
      ctx.save();
      ctx.globalCompositeOperation = 'destination-over';
      ctx.fillStyle = "#FFF";
      ctx.fillRect(0, 0, chart.width, chart.height);
      ctx.restore();

      // Download image
      let canvas = document.getElementById(id).toDataURL('image/png')
      let link = document.createElement('a')
      link.download = name || 'Grafico'
      link.href = canvas
      link.click()
    },
    parseFilters(filters) {
      let filter = { ...filters };
      const { gestao, centres, formaVenda, respostaUnica } = filters;

      const gestoes = this.filtro.listas.gestao.map(c => c.value).join(',')
      const lojas = this.filtro.listas.centres.map(c => c.value).join(",");

      filter.gestao = gestao === 0 ? gestoes : gestao;
      filter.centres = centres === 0 ? lojas : centres;

      filter.respostaUnica = respostaUnica;

      if (typeof filter.centres === 'number') {
        filter.listaPorVendedor = true
      }

      return filter;
    },
    getPercentualTotal() {
      const divisor = this.totalClientesGrupo > 0 ? this.totalClientesGrupo : 1;
      return ((this.totalRecords * 100) / divisor || 0)
        .toFixed(2)
        .replace(".", ",");
    },
    filterLojas() {
      const { gestao, centres } = this.filtro.fields;
      const { centresDefault } = this.filtro.listas;
      this.filtro.listas.centres = gestao === 0 ? centresDefault
        : centresDefault.filter(l => l.gestao === gestao || l.value === 0)

      const gestaoCentres = centresDefault.find(c => c.value === centres)
      if (gestaoCentres.gestao !== gestao) {
        this.filtro.fields.centres = 0
      }
    }
  },
  async created() {
    await this.search();
  },
  computed: {
    totalClientesGrupo: function() {
      return session.get("total-clientes");
    },
    totalRows: function() {
      return this.totalRecords;
    },
    exportFields: function() {
      const obj = {};
      return this.fields.reduce((item, key) => {
        obj[key["label"]] = key["key"];
        return obj;
      }, {});
    },
    exportMotivosDet: function() {
      const obj = {};
      return this.fieldsMot.reduce((item, key) => {
        if (key['label'] !== '') {
          obj[key["label"]] = key["key"];
        }
        return obj;
      }, {});
    },
    displayChart: function() {
      if (this.charts.Motivos.datasets) {
        const data = this.charts.Motivos.datasets[0].data
        const sum = data.reduce((acc, curr) => Number(acc) + Number(curr))
        if (data.length === 0 || data[0] === '' || sum === 0) {
          return false
        }
      }
      return true
    }
  }
};
</script>
