var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "animated fadeIn" },
    [
      _c(
        "b-card",
        { attrs: { "header-tag": "header", "footer-tag": "footer" } },
        [
          _c("div", { attrs: { slot: "header" }, slot: "header" }, [
            _c("strong", [_vm._v("Filtros")])
          ]),
          _c(
            "div",
            { staticClass: "p-1 mt-2" },
            [
              _c(
                "b-row",
                { staticClass: "mb-1" },
                [
                  _c(
                    "b-col",
                    {
                      staticClass: "d-md-block",
                      attrs: { sm: "12", md: "6", lg: "4" }
                    },
                    [
                      _c(
                        "b-button-toolbar",
                        { staticClass: "float-left" },
                        [
                          _c(
                            "b-form-radio-group",
                            {
                              staticClass: "mr-3",
                              attrs: {
                                buttons: "",
                                "button-variant": "outline-primary",
                                name: "radiosBtn"
                              },
                              on: { change: _vm.changePeriodo },
                              model: {
                                value: _vm.filtro.fields.periodo,
                                callback: function($$v) {
                                  _vm.$set(_vm.filtro.fields, "periodo", $$v)
                                },
                                expression: "filtro.fields.periodo"
                              }
                            },
                            [
                              _c(
                                "b-form-radio",
                                {
                                  staticClass: "mx-0",
                                  attrs: { value: "Hoje" }
                                },
                                [_vm._v("Hoje")]
                              ),
                              _c(
                                "b-form-radio",
                                {
                                  staticClass: "mx-0",
                                  attrs: { value: "Ontem" }
                                },
                                [_vm._v("Ontem")]
                              ),
                              _c(
                                "b-form-radio",
                                {
                                  staticClass: "mx-0",
                                  attrs: { value: "Semana" }
                                },
                                [_vm._v("Semana")]
                              ),
                              _c(
                                "b-form-radio",
                                {
                                  staticClass: "mx-0",
                                  attrs: { value: "Mes" }
                                },
                                [_vm._v("Mês")]
                              ),
                              _c(
                                "b-form-radio",
                                {
                                  staticClass: "mx-0",
                                  attrs: { value: "Periodo" }
                                },
                                [_vm._v("Período")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("b-col", { attrs: { sm: "12", md: "6" } }, [
                    _c(
                      "div",
                      { staticClass: "custom-control custom-checkbox pt-1" },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.filtro.fields.respostaUnica,
                              expression: "filtro.fields.respostaUnica"
                            }
                          ],
                          staticClass: "custom-control-input",
                          attrs: { type: "checkbox", id: "cbRespostaUnica" },
                          domProps: {
                            checked: Array.isArray(
                              _vm.filtro.fields.respostaUnica
                            )
                              ? _vm._i(_vm.filtro.fields.respostaUnica, null) >
                                -1
                              : _vm.filtro.fields.respostaUnica
                          },
                          on: {
                            change: function($event) {
                              var $$a = _vm.filtro.fields.respostaUnica,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.filtro.fields,
                                      "respostaUnica",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.filtro.fields,
                                      "respostaUnica",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(
                                  _vm.filtro.fields,
                                  "respostaUnica",
                                  $$c
                                )
                              }
                            }
                          }
                        }),
                        _c(
                          "label",
                          {
                            staticClass: "custom-control-label",
                            attrs: { for: "cbRespostaUnica" }
                          },
                          [
                            _vm._v(
                              'Considerar respostas de seleção múltipla como resposta única no "Motivos Detalhe"'
                            )
                          ]
                        )
                      ]
                    )
                  ])
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "mb-1" },
                [
                  _c(
                    "b-col",
                    { staticClass: "pr-md-0 mb-1 mb-md-0", attrs: { sm: "4" } },
                    [
                      _c("b-form-select", {
                        attrs: {
                          plain: true,
                          options: _vm.filtro.listas.gestao
                        },
                        on: { change: _vm.filterLojas },
                        model: {
                          value: _vm.filtro.fields.gestao,
                          callback: function($$v) {
                            _vm.$set(_vm.filtro.fields, "gestao", $$v)
                          },
                          expression: "filtro.fields.gestao"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { staticClass: "pr-md-0 mb-1 mb-md-0", attrs: { sm: "4" } },
                    [
                      _c("b-form-select", {
                        attrs: {
                          plain: true,
                          options: _vm.filtro.listas.centresFiltradosPorGestao
                        },
                        model: {
                          value: _vm.filtro.fields.centres,
                          callback: function($$v) {
                            _vm.$set(_vm.filtro.fields, "centres", $$v)
                          },
                          expression: "filtro.fields.centres"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "mb-1" },
                [
                  _c(
                    "b-col",
                    {
                      staticClass: "pr-md-0 pr-1",
                      attrs: { cols: "6", sm: "6", md: "4" }
                    },
                    [
                      _c("b-form-input", {
                        attrs: { plain: true, type: "date" },
                        on: { change: _vm.dataChanged },
                        model: {
                          value: _vm.filtro.fields.dataIni,
                          callback: function($$v) {
                            _vm.$set(_vm.filtro.fields, "dataIni", $$v)
                          },
                          expression: "filtro.fields.dataIni"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    {
                      staticClass: "pr-md-0 pl-1 pl-md-3",
                      attrs: { cols: "6", sm: "6", md: "4" }
                    },
                    [
                      _c("b-form-input", {
                        attrs: { plain: true, type: "date" },
                        on: { change: _vm.dataChanged },
                        model: {
                          value: _vm.filtro.fields.dataFim,
                          callback: function($$v) {
                            _vm.$set(_vm.filtro.fields, "dataFim", $$v)
                          },
                          expression: "filtro.fields.dataFim"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { sm: "1" } },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "my-2 my-sm-0 text-white",
                          class: { "btn-block": _vm.$mq === "sm" },
                          attrs: { variant: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.search()
                            }
                          }
                        },
                        [_c("i", { staticClass: "fa fa-search" })]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { cols: "12", md: "12", lg: "4" } },
            [
              _c(
                "b-card",
                { attrs: { "no-body": true } },
                [
                  _c(
                    "b-card-body",
                    {
                      staticClass: "clearfix text-center text-white p-3",
                      attrs: { "body-bg-variant": "primary" }
                    },
                    [
                      _c("div", { staticClass: "h2 mb-1 font-weight-bold" }, [
                        _vm._v(_vm._s(_vm._f("number")(_vm.qtd_realizada)))
                      ]),
                      _c("div", { staticClass: "h5" }, [
                        _vm._v("Vendas Realizadas")
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "12", md: "12", lg: "4" } },
            [
              _c(
                "b-card",
                { attrs: { "no-body": true } },
                [
                  _c(
                    "b-card-body",
                    {
                      staticClass: "clearfix text-center text-white p-3",
                      attrs: { "body-bg-variant": "primary" }
                    },
                    [
                      _c("div", { staticClass: "h2 mb-1 font-weight-bold" }, [
                        _vm._v(_vm._s(_vm._f("number")(_vm.qtd_perdida)))
                      ]),
                      _c("div", { staticClass: "h5" }, [
                        _vm._v("Vendas Perdidas")
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "12", md: "12", lg: "4" } },
            [
              _c(
                "b-card",
                { attrs: { "no-body": true } },
                [
                  _c(
                    "b-card-body",
                    {
                      staticClass: "clearfix text-center text-white p-3",
                      attrs: { "body-bg-variant": "primary" }
                    },
                    [
                      _c("div", { staticClass: "h2 mb-1 font-weight-bold" }, [
                        _vm._v(
                          _vm._s(_vm._f("number")(_vm.taxa_conversao)) + "%"
                        )
                      ]),
                      _c("div", { staticClass: "h5" }, [
                        _vm._v("Taxa de Conversão")
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-card",
        { staticClass: "mr-md-12" },
        [
          _c(
            "div",
            {
              staticClass: "text-black",
              attrs: { slot: "header" },
              slot: "header"
            },
            [
              _c("div", { staticClass: "d-flex" }, [
                _c("div", { staticClass: "d-flex-inline mr-auto" }, [
                  _c("strong", [_vm._v("Motivos em Percentual")])
                ]),
                _vm.displayChart
                  ? _c("div", { staticClass: "d-flex-inline ml-auto" }, [
                      _c("i", {
                        staticClass:
                          "fa fa-download text-primary cursor-pointer",
                        attrs: { title: "Exportar" },
                        on: {
                          click: function($event) {
                            return _vm.export2image(
                              "chart-pie-0676",
                              "Motivos em Percentual"
                            )
                          }
                        }
                      })
                    ])
                  : _vm._e()
              ])
            ]
          ),
          _vm.loaded && _vm.displayChart
            ? _c("pie", {
                attrs: {
                  chartdata: _vm.charts.Motivos,
                  height: 400,
                  chartId: "chart-pie-0676",
                  percentFormat: true
                }
              })
            : _vm._e(),
          !_vm.displayChart
            ? _c(
                "div",
                { staticClass: "alert alert-secondary text-center mb-0" },
                [_vm._v("\n      Nenhum resultado encontrado.\n    ")]
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { cols: "12", sm: "12", lg: "12" } },
            [
              _c(
                "b-card",
                { attrs: { "header-tag": "header" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "float-left",
                      attrs: { slot: "header" },
                      slot: "header"
                    },
                    [
                      _c("strong", [
                        _vm._v("Motivos por " + _vm._s(_vm.tableTitle))
                      ])
                    ]
                  ),
                  _vm.exportable
                    ? _c(
                        "div",
                        {
                          staticClass: "float-right",
                          attrs: { slot: "header" },
                          slot: "header"
                        },
                        [
                          _c(
                            "download-excel",
                            {
                              attrs: {
                                name:
                                  (_vm.item.name ||
                                    "Motivos por " + _vm.tableTitle) + ".xls",
                                fields: _vm.exportFields,
                                data: _vm.items
                              }
                            },
                            [
                              _c("i", {
                                staticClass: "fa fa-download cursor-pointer",
                                attrs: { alt: "Exportar", title: "Exportar" }
                              })
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("b-table", {
                    staticClass: "rotate-header",
                    attrs: {
                      items: _vm.items,
                      fields: _vm.fields,
                      hover: "",
                      small: "",
                      responsive: "md"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { cols: "12", sm: "12", lg: "6" } },
            [
              _c(
                "b-card",
                [
                  _c(
                    "div",
                    {
                      staticClass: "text-black",
                      attrs: { slot: "header" },
                      slot: "header"
                    },
                    [
                      _c("div", { staticClass: "d-flex" }, [
                        _c("div", { staticClass: "d-flex-inline mr-auto" }, [
                          _c("strong", [_vm._v("Motivos Detalhe - Gráfico")])
                        ])
                      ])
                    ]
                  ),
                  _vm._l(_vm.motivosDetTemp, function(item, index) {
                    return _c(
                      "ul",
                      {
                        key: index,
                        staticClass: "horizontal-bars pl-0",
                        attrs: { id: "chart-pie-23" }
                      },
                      [
                        _c("div", { staticClass: "progress-group" }, [
                          _c("div", { staticClass: "progress-group-header" }, [
                            _c("span", { staticClass: "title" }, [
                              _vm._v(_vm._s(item.desc))
                            ]),
                            _c(
                              "span",
                              { staticClass: "ml-auto font-weight-bold" },
                              [
                                _vm._v(_vm._s(item.qtd) + " "),
                                _c(
                                  "span",
                                  { staticClass: "text-muted small" },
                                  [
                                    _vm._v(
                                      "(" +
                                        _vm._s(
                                          _vm._f("currency")(item.perc, {
                                            symbol: ""
                                          })
                                        ) +
                                        " %)"
                                    )
                                  ]
                                )
                              ]
                            )
                          ]),
                          _c(
                            "div",
                            { staticClass: "progress-group-bars" },
                            [
                              _c("b-progress", {
                                staticClass: "progress-sm",
                                attrs: {
                                  height: "{}",
                                  value: item.perc,
                                  variant: "primary"
                                }
                              })
                            ],
                            1
                          )
                        ])
                      ]
                    )
                  })
                ],
                2
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "12", sm: "12", lg: "6" } },
            [
              _c(
                "b-card",
                { attrs: { "header-tag": "header" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "float-left text-black",
                      attrs: { slot: "header" },
                      slot: "header"
                    },
                    [_c("strong", [_vm._v("Motivos Detalhe - Tabela")])]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "float-right",
                      attrs: { slot: "header" },
                      slot: "header"
                    },
                    [
                      _c(
                        "download-excel",
                        {
                          staticClass: "float-left",
                          attrs: {
                            name: "Motivos Detalhe.xls",
                            fields: _vm.exportMotivosDet,
                            data: _vm.motivosDetTemp
                          }
                        },
                        [
                          _c("i", {
                            staticClass: "fa fa-download cursor-pointer",
                            attrs: { alt: "Exportar", title: "Exportar" }
                          })
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-table",
                    {
                      attrs: {
                        items: _vm.motivosDetTemp,
                        fields: _vm.fieldsMot,
                        hover: "",
                        small: "",
                        responsive: "md",
                        "foot-clone": ""
                      },
                      on: { "row-clicked": _vm.loadNextMotivos },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "proximo",
                            fn: function(data) {
                              return data.item.selecao && !_vm.respostaUnicaTemp
                                ? [
                                    _c("i", {
                                      staticClass: "fa fa-arrow-right",
                                      on: { click: _vm.loadNextMotivos }
                                    })
                                  ]
                                : undefined
                            }
                          }
                        ],
                        null,
                        true
                      )
                    },
                    [
                      _c("template", { slot: "FOOT_desc" }, [
                        _vm._v("\n          Total\n        ")
                      ]),
                      _c("template", { slot: "FOOT_qtd" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.motivosDetTemp[0]
                                ? _vm.motivosDetTemp[0].Total
                                : 0
                            ) +
                            "\n        "
                        )
                      ]),
                      _c("template", { slot: "FOOT_perc" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm._f("currency")(_vm.totalPercent, {
                                symbol: ""
                              })
                            ) +
                            "\n        "
                        )
                      ])
                    ],
                    2
                  ),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        {
                          staticClass: "text-right",
                          attrs: { cols: "12", sm: "12" }
                        },
                        [
                          _vm.posMotivo.length > 1
                            ? _c(
                                "b-button",
                                {
                                  attrs: {
                                    size: "sm",
                                    variant: "outline-primary"
                                  },
                                  on: { click: _vm.loadBackMotivos }
                                },
                                [_vm._v("Voltar")]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }